<template>
  <error-loading v-if="error" />
  <b-row v-else>
    <b-col cols="12" v-if="media">
      <report :youtubeData="youtubeData" :media="media"></report>
    </b-col>
    <b-col cols="12" v-if="role !== 'espejo' && priceData">
      <profile-value :priceData="priceData"></profile-value>
    </b-col>
    <b-col cols="12" v-if="metrics">
      <report-metrics :data="metrics" />
    </b-col>
    <b-col cols="12" v-if="data.report.features.audience_age_gender && data.report.features.audience_age_gender.data.length > 0">
      <age-gender :graphData="ageGender" />
    </b-col>
    <b-col cols="12">
      <feature v-if="this.data.features.blogger_mentions_performance"
        :youtubeData="feature"
      ></feature>
    </b-col>
    <b-col cols="12">
      <country-lang :languages="lang" :countries="geo" />
    </b-col>
    <b-col cols="12">
      <metrics v-if="metricsHistory" :youtubeData="metricsHistory"></metrics>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
export default {
  components: {
    BRow,
    BCol,
    Report: () => import('@/views/pages/profile/youtube/Report.vue'),
    ProfileValue: () => import('./ProfileValue.vue'),
    ReportMetrics: () => import('./ReportMetrics.vue'),
    Feature: () => import('@/views/pages/profile/youtube/Feature.vue'),
    Metrics: () => import('@/views/pages/profile/youtube/Metrics.vue'),
    AgeGender: () => import('./AgeGender.vue'),
    CountryLang: () => import('./CountryLang.vue'),
    ErrorLoading: () => import('../ErrorLoading.vue')
  },
  props: {
    data: {
      Type: Object,
      required: false
    },
    error: {
      Type: Boolean,
      required: true
    }
  },
  data() {
    return {
      role: 'brand'
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.groups[0].name
  },
  computed: {
    youtubeData() {
      return this.data.report
    },
    media() {
      return this.data.media
    },
    priceData() {
      return this.data.report.video_integration_price
    },
    metrics() {
      return this.data.report.metrics
    },
    ageGender() {
      return this.data.report.features.audience_age_gender.data
    },
    feature() {
      return this.data.features.blogger_mentions_performance
    },
    lang() {
      if (this.data.report.features.audience_languages && this.data.report.features.audience_languages.data) return this.data.report.features.audience_languages.data
      return []
    },
    geo() {
      if (this.data.report.features.audience_geo && this.data.report.features.audience_geo.data) return this.data.report.features.audience_geo.data
      return []
    },
    metricsHistory() {
      return this.data.metrics_history
    }
  }
}
</script>

